/**
 * This file will configure various resources for your application.
 * - Logging to Sumologic (More configuration required - see below)
 * - Analytics tracking through Adobe Target Events
 * - Daxi (The Origin Digital http request library)
 *
 * Anything else required before your app is loaded can be included in this
 * file.
 */

import { configAnalytics, configSumo } from "@origin-digital/reporting-client";
import SumoLogger from "sumo-logger";
import { config, appName } from "./config";

const envAppName = `${appName}-${config.env}`;

configAnalytics({
  appName,
  debugToConsole: config.analyticsDebugToConsole
});

configSumo({
  SumoLogger,
  appName: envAppName,
  consoleLogLevel: config.logging.consoleLogLevel,
  endpoint: config.logging.sumoEndpoint,
  sendOnErrors: config.logging.sumoSendOnErrors,
  sourceEnv: config.env,
  sumoLogLevel: config.logging.sumoLogLevel
});

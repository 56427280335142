import { IRequestData, IResponseData } from "../utils/fetch";

export const errors = {
  AUTH_CALLBACK_AUTH0_CONFIG: {
    errorCode: "authcallback_auth0_configurations_not_provided",
    body: "Auth0 configurations were not provided "
  },
  AUTH_CALLBACK_NOT_ALLOWED_DOMAIN: {
    errorCode: "authcallback_not_allowed_domain_found",
    body: "Domain of the returnTo url's is not in the allowed domains list"
  },
  AUTH_CALLBACK_GENERIC: {
    errorCode: "authcallback_generic_error",
    body: "authcallback generic error message"
  },
  AUTH_CALLBACK_AUTH0_CALL: {
    errorCode: "authcallback_auth0_call_failed",
    body: "handleAuthentication method of origin-auth failed in authcallback"
  },
  AUTH_CALLBACK_AUTH_TOKEN_API: {
    errorCode: "authcallback_authtoken_api_failed",
    body: "authtoken dapi failed."
  },
  AUTH_CALLBACK_USER_SESSION_API: {
    errorCode: "authcallback_user_session_api_failed",
    body: "user-session aem servlet call failed"
  },
  AUTH_CALLBACK_SCOPED_MAGIC_LINKS: {
    errorCode: "authcallback_scoped_magic_links",
    body: "failure on scoped magic links redirect"
  }
};

export interface IFetchErrorInfo {
  request?: IRequestData;
  response?: IResponseData | undefined;
  error?: Error;
  code?: any;
}

export class ErrorFetch extends Error {
  errorInfo: IFetchErrorInfo;
  constructor(message: string, errorInfo: IFetchErrorInfo) {
    super(message);
    Object.setPrototypeOf(this, new.target.prototype);
    this.errorInfo = errorInfo;
  }
}

export function getFetchErrorInfo(
  requestData: IRequestData,
  responseData?: IResponseData | undefined,
  error?: Error | undefined,
  errorCode?: any
): IFetchErrorInfo {
  return {
    request: requestData,
    ...(responseData ? { response: responseData } : {}),
    ...(error ? { error: error } : {}),
    ...(errorCode ? { code: errorCode } : {})
  };
}

import { hasScopedJwt, OriginSpaAuthClient } from "@origin-digital/origin-auth";
import { IAuth0Config } from "@origin-digital/origin-auth/src/interfaces";
import jwtDecode from "jwt-decode";
import includes from "lodash/includes";
import queryString from "query-string";
import { logout } from "./logout";

interface IJwt {
  scope: string;
}

enum AUTH_ERROR {
  LOGIN_REQUIRED = "login_required"
}

const extractAccessToken = (queryParamString: string): string => {
  const queryParams = queryString.parse(queryParamString) as {
    [key: string]: string;
  };
  return queryParams.access_token;
};

const containsScope = (accessToken: string, scope: string): boolean => {
  const decodedJwt = jwtDecode<IJwt>(accessToken);
  const scopes = decodedJwt.scope.split(" ");
  return includes(scopes, scope);
};

const cleanupScopedSession = async (
  auth0Config: IAuth0Config
): Promise<void> => {
  if (hasScopedJwt(auth0Config)) {
    await logout("/auth/#/login/start");
  }
};

const isLoggedIn = (accessToken?: string) => {
  return accessToken ? containsScope(accessToken, "all") : false;
};

/**
 * This function will return access token
 * or silent errors related to no existing session
 * and returns undefined
 * @param spaAuthClient Origin SAP Client
 */
const getTokenSilently = async (spaAuthClient: OriginSpaAuthClient) => {
  try {
    return await spaAuthClient.getJwtSilently();
  } catch (e) {
    if (e.error !== AUTH_ERROR.LOGIN_REQUIRED) {
      throw e;
    }
  }
};

export {
  extractAccessToken,
  containsScope,
  cleanupScopedSession,
  isLoggedIn,
  getTokenSilently
};

import {
  getLocationOrigin,
  OriginSpaAuthClient,
  OriginWebAuthClient
} from "@origin-digital/origin-auth";
import { StorageKeys } from "@origin-digital/platform-enums";
import { isNative } from "@origin-digital/platform-helpers";
import cookieManager from "js-cookie";
import { fireAnalytics } from "../utils/analytics";
import { getJwtToken } from "../utils/jwt";
import { krakenLogout } from "../utils/kraken";
import { getAuth0Config, getTrackingUserData, log } from "../utils/utils";

export const logoutRedirectPath = "/for-home/my-account/logout.html";

const NATIVE_LOGOUT_QUERY_STRING_IDENTIFIER = "nativeLogout";
/**
 * check if logout was requested from native
 *  - latest version of the app sends nativeLogout=true as query param (from v2.7.15)
 *  - it still supports native=true for backwards compatibility, can be removed after 3-4 months (~ 01/2021)
 */
export const wasLogoutRequestFromNative = (): boolean => {
  const reg = new RegExp(`[?&]${NATIVE_LOGOUT_QUERY_STRING_IDENTIFIER}=`, "i");
  const nativeLogout = reg.exec(window.location.href);
  return nativeLogout !== null || isNative();
};

export async function logout(returnToPath?: string) {
  try {
    await executeLogout();
  } catch (ex) {
    await log("error", `ACB[logout]: executeLogout failed - ${ex}`);
  } finally {
    cleanUp();
    logoutFromAuth0(returnToPath || logoutRedirectPath);
  }
}

async function executeLogout() {
  const customerData = await getTrackingUserData();
  try {
    await logoutFromBackend();
    await fireAnalytics(window.location.host, {
      action: "logout",
      status: "start",
      ...customerData
    }).catch(() => {});
  } catch (ex) {
    await log("error", "logout-micro-site: sap logout failed");
    await fireAnalytics(window.location.host, {
      action: "logout",
      status: "error",
      ...customerData
    });
  }
}

const prepareHeaders = async () => {
  const headers: HeaderRecord = {
    "Content-Type": "application/json",
    "x-api-key": getAuth0Config().apiKey
  };
  try {
    const jwt = await getJwtToken();
    if (jwt) {
      headers["Authorization"] = "Bearer " + jwt.accessToken;
    }
  } catch (error) {
    await log("warn", "ACB[logout]: could not fetch jwt", error);
  }
  return headers;
};

export const getAccessToken = (
  headers: Record<string, string>
): string | undefined => {
  return headers["Authorization"]
    ? headers["Authorization"].split(" ")[1]
    : undefined;
};

async function logoutFromBackend() {
  const headers = await prepareHeaders();
  await krakenLogout(headers);
}

function logoutFromAuth0(returnToPath: string) {
  const webAuthClient = new OriginWebAuthClient(getAuth0Config());
  const returnTo = wasLogoutRequestFromNative()
    ? returnToPath
    : `${getLocationOrigin()}${returnToPath}`;
  webAuthClient.logoutFromAuth0(returnTo);
}

function cleanUp() {
  //remove client-side cookies
  cookieManager.remove("pmid", { path: "/" });
  deleteIntercomSession();
  //reset my-account aem context hub
  if (window.ContextHub) {
    window.ContextHub.getStore("originmyacc").reset(false);
  }

  if (!localStorage.getItem("originStore.logoutType")) {
    localStorage.setItem("originStore.logoutType", "manual");
  }

  const originSpaAuth = new OriginSpaAuthClient(getAuth0Config());
  // clean up cache
  originSpaAuth.logout();

  Object.keys(StorageKeys).forEach((key: any) => {
    localStorage.removeItem(StorageKeys[key as keyof typeof StorageKeys]);
    sessionStorage.removeItem(StorageKeys[key as keyof typeof StorageKeys]);
  });
}

function deleteIntercomSession(): void {
  document.cookie
    .split(";")
    .map(cookie => cookie.trim())
    .filter(cookie => /^intercom-.*$/.test(cookie))
    .map(s => s.split("=")[0])
    .forEach(cookie => {
      cookieManager.remove(cookie, {
        path: "/",
        ...(window.location.host !== "localhost" && {
          domain: `.${window.location.host.substring(
            window.location.host.indexOf(".") + 1
          )}`
        })
      });
    });

  Object.keys(sessionStorage)
    .filter(key => /^intercom.*$/.test(key))
    .forEach(key => {
      sessionStorage.removeItem(key);
    });
  Object.keys(localStorage)
    .filter(key => /^intercom.*$/.test(key))
    .forEach(key => {
      localStorage.removeItem(key);
    });
}

import { ErrorFetch, getFetchErrorInfo, IFetchErrorInfo } from "../errors";

export interface IResponseData {
  data?: any;
  status?: number;
  statusText?: string;
  headers?: Headers;
}

export interface IRequestData {
  url: string;
  headers: any;
}

export async function doFetch(
  requestUrl: string,
  requestOptions: RequestInit,
  errorMsg: string,
  errorCode?: any
): Promise<IResponseData> {
  let response;
  const headers = { ...requestOptions.headers, method: requestOptions.method };
  try {
    response = await fetch(requestUrl, requestOptions);
    return handleFetchResponse(
      requestUrl,
      headers,
      response,
      errorMsg,
      errorCode
    );
  } catch (error) {
    if (!(error instanceof ErrorFetch)) {
      const errInfo: IFetchErrorInfo = getFetchErrorInfo(
        {
          url: requestUrl,
          headers: headers
        },
        undefined,
        error,
        errorCode
      );
      throw new ErrorFetch(errorMsg, errInfo);
    } else {
      throw error;
    }
  }
}

export async function handleFetchResponse(
  url: string,
  headers: any,
  response: Response,
  errorMessage: string,
  errorCode?: any
): Promise<IResponseData> {
  if (!response.ok) {
    const requestData: IRequestData = { url: url, headers };
    const responseData: IResponseData = {
      status: response.status,
      statusText: response.statusText,
      headers: response.headers
    };
    const errInfo: IFetchErrorInfo = getFetchErrorInfo(
      requestData,
      responseData,
      undefined,
      errorCode
    );
    throw new ErrorFetch(errorMessage, errInfo);
  }
  const data =
    response.headers.get("content-length") === "0"
      ? null
      : await response.json();

  return {
    data,
    status: response.status,
    statusText: response.statusText,
    headers: response.headers
  };
}

export const config: IAppConfig =
  typeof TAL !== "undefined"
    ? TAL.config
    : typeof (window as any).__STORYBOOK_ADDONS !== "undefined"
    ? {}
    : (null as any);

if (config == null) {
  throw new Error("App config is missing");
}
export const appName = TAL.config.appName || "authcallback";

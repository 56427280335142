import * as React from "react";
import { Spinner } from "./Spinner";
import { handleAuthentication } from "../services/auth";
import { parseReturnToQueryParam } from "../utils/utils";
import { RETURN_TO } from "../const";

interface IProps {
  location: Location;
}
export class CallbackUI extends React.Component<IProps, {}> {
  componentDidMount() {
    const returnToPath = parseReturnToQueryParam(this.props.location.search);

    // store returnTo path in session storage so it survives redirects to
    // auth0 and so on.
    if (returnToPath) {
      sessionStorage.setItem(RETURN_TO, returnToPath);
    }
    handleAuthentication();
  }
  render() {
    return <Spinner />;
  }
}

export const APP_ID = "authcallbackassets/authcallback";
export const APP_NAME_AUTH_CALLBACK = "authcallback";
export const APP_NAME_LOGOUT = "logout";
export const AUTH0_CONFIG = "auth0Config";
export const RETURN_TO = "returnTo";

export const MYACC_HOME_RESIDENTIAL = "/my";

export const AUTH_ADOBE_LOGIN_TYPE_UNKNOWN = "unknown";

export const ADOBE_LOGIN_EVENT_STATUS_SUCCESS = "success";
export const ADOBE_LOGIN_EVENT_TYPE_KMLI = "keep-me-logged-in:checked";
export const ADOBE_LOGIN_EVENT_TYPE_MAGIC_FULL_SCOPE = "magic-link-ml";
export const ADOBE_LOGIN_EVENT_TYPE_MAGIC_RESTRICTED_SCOPE = "magic-link-sml";
export const ADOBE_LOGIN_EVENT_TYPE_MANUAL_CHECKED = "manual:checked";
export const ADOBE_LOGIN_EVENT_TYPE_MANUAL_UNCHECKED = "manual:unchecked";

import * as React from "react";
import { handleMagicLink } from "../services/MagicLinkService";
import { extractAccessToken } from "../services/TokenService";
import { parseReturnToQueryParam } from "../utils/utils";
import { Spinner } from "./Spinner";

interface IProps {
  location: Location;
}

export class MagicLinkHandler extends React.Component<IProps, {}> {
  componentDidMount() {
    const accessToken = extractAccessToken(this.props.location.search);
    const returnToParam = parseReturnToQueryParam(this.props.location.search);
    if (isAllowedRedirect(returnToParam)) {
      handleMagicLink(accessToken, returnToParam);
    }
  }

  render() {
    return <Spinner />;
  }
}

function isAllowedRedirect(redirectUrl: string | undefined) {
  const allowedDomains = [
    "portal.originzero.com.au",
    "businessportal.origin.com.au",
    "www.originenergy.com.au",
    "businessportal.stg.origin.com.au",
    "portal.stg.originzero.com.au",
    "staticsite-cdn-staging.origindigital-pac.com.au",
    "portal.zero-dev.int.origin.com.au",
    "businessportal.dev.origin.com.au",
    "staticsite-cdn-test.origindigital-dac.com.au",
    "staticsite-cdn-dev.origindigital-dac.com.au",
    "espec-shopfrontapi-service-test.originrx-tac.com.au",
    "espec-shopfrontapi-service-prod.originrx-pac.com.au"
  ];
  return redirectUrl && allowedDomains.includes(getUrlDomain(redirectUrl));
}

function getUrlDomain(urlStr: string) {
  const url = new URL(urlStr);
  return url.hostname;
}

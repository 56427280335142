import { getAuth0Config } from "./utils";
import { doFetch } from "./fetch";

export const krakenLogout = async (headers: Record<string, string>) => {
  const krakenLogoutUrl = `${getAuth0Config().krakenApiPath}/v1/authtoken`;

  await doFetch(
    krakenLogoutUrl,
    {
      method: "DELETE",
      headers
    },
    "Logout Failed"
  );
};

import * as React from "react";
import { Spinner } from "./Spinner";
import { logout } from "../services/logout";
import { parseReturnToQueryParam } from "../utils/utils";

interface IProps {
  location: Location;
}

export class LogoutUI extends React.Component<IProps, {}> {
  componentDidMount() {
    logout(parseReturnToQueryParam(this.props.location.search));
  }
  render() {
    return <Spinner />;
  }
}

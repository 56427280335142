import * as React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { LogoutUI } from "./LogoutUI";
import { CallbackUI } from "./CallbackUI";
import { MagicLinkHandler } from "./MagicLinkHandler";
import { NativeOpenInWebHandler } from "./NativeOpenInWebHandler";

export const App = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/auth/callback" component={CallbackUI} />
      <Route exact path="/auth/ml" component={NativeOpenInWebHandler} />
      <Route exact path="/auth/sml" component={MagicLinkHandler} />
      <Route exact path="/logout" component={LogoutUI} />
    </Switch>
  </BrowserRouter>
);

import {
  ADOBE_LOGIN_TYPE_AUTO,
  ADOBE_LOGIN_TYPE_MANUAL,
  ADOBE_ANALYTICS_LOGIN_TYPE
} from "@origin-digital/origin-auth";
import {
  ADOBE_LOGIN_EVENT_TYPE_KMLI,
  ADOBE_LOGIN_EVENT_TYPE_MANUAL_CHECKED,
  ADOBE_LOGIN_EVENT_TYPE_MANUAL_UNCHECKED,
  ADOBE_LOGIN_EVENT_TYPE_MAGIC_FULL_SCOPE,
  AUTH_ADOBE_LOGIN_TYPE_UNKNOWN,
  ADOBE_LOGIN_EVENT_TYPE_MAGIC_RESTRICTED_SCOPE
} from "../const";
import { isRememberMe } from "./utils";

export async function fireAnalytics(
  host: string,
  input: {
    action: "login" | "logout";
    status: "start" | "success" | "error";
    [name: string]: string;
  }
) {
  //Set variables if not passed
  let data: any = {
    server: host,
    ch: "originenergy",
    g: document ? document.location.href : "",
    r: document ? document.referrer : "" // Try to get softly in case document not ready yet.
  };

  const normaliseBpid = function(val: string) {
    let returnVal;
    if (/^\d+$/.test(val)) {
      // If BPID is all numbers, return as is.
      returnVal = val;
    } else {
      try {
        returnVal = atob(decodeURIComponent(val)); // Some systems encode BPID
      } catch (e) {
        returnVal = val; // Give up and return what we got given.
      }
    }
    return returnVal;
  };

  // Capture customerId regardless of action
  if (input.bpid || input.customerId) {
    let customerId: string;
    if (input.bpid) {
      customerId = data.v39 = normaliseBpid(input.bpid);
    } else {
      customerId = data.v120 = input.customerId;
    }
    data["AdobeCampaignID.as"] = data["xd_bpid.as"] =
      input.action === "login" ? 1 : 2;
    data["AdobeCampaignID.id"] = data["xd_bpid.id"] = customerId;
  }

  // Handle login action
  if (input.action === "login") {
    if (input.status === "success") {
      //Successful login
      data.events = "event22";
      data.pageName = data.ch + ":my-account:login:success";
      if ("loginMethod" in input) {
        data.v37 = input.loginMethod;
      }
    } else if (input.status === "error") {
      //Something went wrong
      data.events = "event20";
      data.pageName = data.ch + ":my-account:login:system-error";
      if ("code" in input) {
        data.v25 = input.code;
      }
    }
  }

  // Handle logout action
  if (input.action === "logout") {
    if (input.status === "start") {
      data.events = "event79";
      data.pageName = data.ch + ":my-account:logout";
    } else if (input.status == "error") {
      data.events = "event20";
      data.pageName = data.ch + ":my-account:logout:system-error";
    }
  }

  //Regex the marketing cloud ID (MCMID)
  const regex = /MCMID\|(\d{38})/;
  const cookie = decodeURI(document.cookie);
  const matches = regex.exec(cookie);
  if (matches !== null) {
    data.mid = matches[1];
  }

  //Build the network request and send beacon
  let aaImgSourceFrag = [];
  for (var key in data) {
    aaImgSourceFrag.push(key + "=" + encodeURIComponent(data[key]));
  }

  let baseURL;
  if (host.endsWith("originenergy.com.au")) {
    baseURL =
      "https://sanmet.originenergy.com.au/b/ss/orig-au-prd/1/G.4--NS/" +
      Math.floor(Math.random() * 1e7) +
      "?AQB=1&ndh=0&"; //Prod
  } else {
    baseURL =
      "https://sanmet.originenergy.com.au/b/ss/orig-au-dev/1/G.4--NS/" +
      Math.floor(Math.random() * 1e7) +
      "?AQB=1&ndh=0&"; //Non-prod
  }

  const url = baseURL + aaImgSourceFrag.join("&") + "&AQE=1";

  return fetch(url);
}

export const saveAnalyticsLoginType = (loginEventType: string) => {
  sessionStorage.setItem(ADOBE_ANALYTICS_LOGIN_TYPE, loginEventType);
};

export const getLoginMethod = () => {
  // login type was stored during handleAuthentication
  const loginType = sessionStorage.getItem(ADOBE_ANALYTICS_LOGIN_TYPE);
  switch (loginType) {
    case ADOBE_LOGIN_TYPE_AUTO:
      // we performed a silent login
      return ADOBE_LOGIN_EVENT_TYPE_KMLI;
    case ADOBE_LOGIN_TYPE_MANUAL:
      // user had to log in manually.
      return isRememberMe()
        ? ADOBE_LOGIN_EVENT_TYPE_MANUAL_CHECKED
        : ADOBE_LOGIN_EVENT_TYPE_MANUAL_UNCHECKED;
    case ADOBE_LOGIN_EVENT_TYPE_MAGIC_FULL_SCOPE:
    case ADOBE_LOGIN_EVENT_TYPE_MAGIC_RESTRICTED_SCOPE:
      return loginType;
    default:
      return AUTH_ADOBE_LOGIN_TYPE_UNKNOWN;
  }
};

import {
  OriginWebAuthClient,
  storeJwt,
  OriginSpaAuthClient,
  decodeJwt,
  verifyJwt
} from "@origin-digital/origin-auth";
import { logger } from "@origin-digital/reporting-client";
import { clearStorage as clearSACStorage } from "@origin-digital/shared-app-context";
import {
  ADOBE_LOGIN_EVENT_TYPE_MAGIC_RESTRICTED_SCOPE,
  APP_ID
} from "../const";
import { errors } from "../errors";
import {
  getAuth0Config,
  getLoggerConfig,
  fireAdobeLoginSuccessEvent
} from "../utils/utils";
import { redirectTo } from "./auth";
import { logout } from "./logout";
import { isSameUser, getJwtClaims } from "../utils/jwt";
import { isLoggedIn, getTokenSilently } from "./TokenService";
import { saveAnalyticsLoginType } from "../utils/analytics";

const handleMagicLink = async (
  accessToken: string,
  returnTo: string | undefined
) => {
  const auth0Config = getAuth0Config();
  const webAuthClient = new OriginWebAuthClient(auth0Config, getLoggerConfig());
  const spaAuthClient: OriginSpaAuthClient = new OriginSpaAuthClient(
    auth0Config,
    getLoggerConfig()
  );

  try {
    const cachedJwt = await getTokenSilently(spaAuthClient);
    const existingJwt = cachedJwt ? decodeJwt(cachedJwt) : undefined;
    const newJwt = decodeJwt(accessToken);
    const sameUser = isSameUser(existingJwt, newJwt);
    const userLoggedIn = isLoggedIn(cachedJwt);
    const claims = getJwtClaims(accessToken);

    if (sameUser && userLoggedIn) {
      logger.debug("[Handle ml]: Same user so keeping token");
      verifyJwt(existingJwt!, auth0Config);
      storeJwt(cachedJwt!, auth0Config);
    } else {
      if (userLoggedIn) {
        logger.debug("[Handle ml]: Logging user out with redirect");
        return logout(`${window.location.pathname}${window.location.search}`);
      } else {
        logger.debug("[Handle ml]: User not logged in, skip logout");
      }
      // clear SAC as leaving the current user there
      // can unexpected cause issues in some apps when switching
      // between users (e.g. loads data for user1 then loads data for user2)
      clearSACStorage();
      logger.debug("[Handle ml]: Storing incoming token");
      storeJwt(accessToken, auth0Config);
    }

    saveAnalyticsLoginType(ADOBE_LOGIN_EVENT_TYPE_MAGIC_RESTRICTED_SCOPE);
    await fireAdobeLoginSuccessEvent();

    const returnToUrl = getReturnUrlByUserType(claims.userType, returnTo);
    logger.debug(`[Handle ml]: Redirecting user to ${returnToUrl}`);
    redirectTo(webAuthClient, returnToUrl, undefined);
  } catch (error) {
    webAuthClient.handleError(errors.AUTH_CALLBACK_SCOPED_MAGIC_LINKS, error);
  }
};

function getReturnUrlByUserType(
  userType?: string,
  returnTo?: string
): string | undefined {
  if (userType) {
    if (userType === "BSP_USER") {
      return window.oetal.config[APP_ID].mbpReturnToUrl;
    } else if (userType === "C_AND_I_USER") {
      return window.oetal.config[APP_ID].cAndIReturnToUrl;
    }
  }
  return returnTo;
}

export { handleMagicLink, getReturnUrlByUserType };

import * as React from "react";
import { Spinner } from "./Spinner";
import { tokenExchange } from "../services/tokenExchange";
import { parseReturnToQueryParam } from "../utils/utils";
import queryString from "query-string";

interface IProps {
  location: Location;
}

export class NativeOpenInWebHandler extends React.Component<IProps, {}> {
  componentDidMount() {
    const queryParams = queryString.parse(this.props.location.search) as {
      [key: string]: string;
    };
    const accessToken = queryParams.access_token;
    tokenExchange(
      accessToken,
      parseReturnToQueryParam(this.props.location.search)
    );
  }
  render() {
    return <Spinner />;
  }
}

import {
  getAllowedDomains,
  getLocationOrigin,
  IAuthTokens,
  isAuthenticated,
  OriginWebAuthClient,
  storeJwt
} from "@origin-digital/origin-auth";
import "url-polyfill";
import { logger } from "@origin-digital/reporting-client";
import jwtDecode from "jwt-decode";
import { APP_ID, MYACC_HOME_RESIDENTIAL, RETURN_TO } from "../const";
import { ErrorFetch, errors } from "../errors";
import { LoginFlowType } from "../utils/LoginFlowType";

import {
  appendQueryParams,
  fireAdobeLoginSuccessEvent,
  getAuth0Config,
  getLoggerConfig,
  getTargetLoginFlow
} from "../utils/utils";
import { cleanupScopedSession } from "./TokenService";
import { JWTClaims, mapJwt } from "../utils/jwt";

/**
 * Entry method to auth0 authentication. After a successful authentication with
 * auth0 this method created the cookies and the context hub required by aem
 * pages before redirection to the referer.
 *
 * Redirects to the hosted login pages if an error occurs.
 *
 */
export async function handleAuthentication() {
  const auth0Config = getAuth0Config();
  const webAuthClient = new OriginWebAuthClient(auth0Config, getLoggerConfig());

  if (!auth0Config || Object.keys(auth0Config).length === 0) {
    webAuthClient.handleError(errors.AUTH_CALLBACK_AUTH0_CONFIG);
    return;
  }

  await cleanupScopedSession(auth0Config);
  let claims: JWTClaims | undefined = undefined;
  try {
    const authTokens: IAuthTokens = await webAuthClient.handleAuthentication();

    if (isAuthenticated(authTokens)) {
      claims = mapJwt(jwtDecode<JWTClaims>(authTokens.access_token));
      const idTokenClaims = mapJwt(jwtDecode(authTokens.id_token));
      const loginFlowType = getTargetLoginFlow(
        claims,
        "isAuthenticatedFn",
        authTokens.access_token
      );

      if (loginFlowType === LoginFlowType.RX) {
        storeJwt(authTokens.access_token, auth0Config);
      } else {
        // loginFlowType === LoginFlowType.UNKNOWN
        throw Error(
          `[login-flow-type] Unknown login flow type, unable to login. Customer type: ${claims &&
            claims.customerType}, Backends: ${claims && claims.backends}`
        );
      }
      const returnToUrl = getReturnUrlByUserType(claims.userType);
      await fireAdobeLoginSuccessEvent();
      return redirectTo(webAuthClient, returnToUrl, idTokenClaims.trackingId);
    }
  } catch (error) {
    await logger.error(
      `[ACB] Error in handleAuthentication for Auth0 User id ${claims &&
        claims.sub}, rawError: ${error}, jsonError: ${JSON.stringify(error)}`
    );
    if (error instanceof ErrorFetch) {
      webAuthClient.handleError(error.errorInfo.code, error.errorInfo);
    } else {
      webAuthClient.handleError(errors.AUTH_CALLBACK_AUTH0_CALL, error);
    }
  }
}

function getReturnUrlByUserType(userType?: string): string | undefined {
  if (userType) {
    if (userType === "BSP_USER") {
      return window.oetal.config[APP_ID].mbpReturnToUrl;
    } else if (userType === "C_AND_I_USER") {
      return window.oetal.config[APP_ID].cAndIReturnToUrl;
    }
  }
}

/**
 * Redirects to the `returnTo` url that was stored in the sessionStorage either by the tal-core
 * or the cms. `returnTo` url must be one of the white listed domains. This method is the last
 * step of the authentication flow.
 *
 * @param authClient
 * @param returnToUrl Optional url to redirect to. If not passed, user will be redirected
 * to `RETURN_TO` value set in session storage or to the dashboard.
 * @param trackingId Optional trackingId used only by passwordless login via link flow.
 */
export function redirectTo(
  authClient: OriginWebAuthClient,
  returnToUrl?: string,
  trackingId: string | undefined = undefined
) {
  const allowedDomains = getAllowedDomains();
  const returnTo = returnToUrl || sessionStorage.getItem(RETURN_TO);
  sessionStorage.removeItem(RETURN_TO);

  if (returnTo) {
    // returnTo is relative - no need to check against domains whitelist
    if (returnTo.startsWith("/")) {
      return location.assign(returnTo);
    }

    // returnTo is absolute, so check against domains whitelist
    for (const domain of allowedDomains) {
      if (new URL(returnTo).hostname.endsWith(domain)) {
        return location.assign(returnTo);
      }
    }

    authClient.handleError(errors.AUTH_CALLBACK_NOT_ALLOWED_DOMAIN, {
      error: "authcallback_not_allowed_domain_found",
      errorDescription: returnTo
    });
  } else {
    const dashboardUrl = appendQueryParams(MYACC_HOME_RESIDENTIAL, trackingId);
    location.assign(`${getLocationOrigin()}${dashboardUrl}`);
  }
}

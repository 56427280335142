import styled from "styled-components";
import { Spinner as SpinnerComponent } from "@origin-digital/style-guide";
import * as React from "react";

export const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background: #f4f4f4;
`;

export const Spinner = () => (
  <SpinnerContainer>
    <SpinnerComponent
      show={true}
      size={75}
      borderWidth={7}
      color={"lightOrange"}
    />
  </SpinnerContainer>
);
